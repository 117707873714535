<template>
  <div class="events">
    <p>Test para todos</p>
    <event-card v-for="event in events" :key="event.id" :event="event"></event-card>
  </div>
</template>

<script>

import EventCard from "@/components/EventCard.vue";
import EventService from "../services/EventService.js";

// @ is an alias to /src
export default {
  name: 'EventList',
  components: {
    EventCard
  },
  data() {
    return {
      events: null
    }
  },
  created() {
    EventService.getEvents()
    .then(response => {
      this.events = response.data
    })
    .catch(error => {
      console.log(error)
    })
  }
}
</script>


<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-content: center;
}
</style>
